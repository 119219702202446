import React from 'react';
import image1 from '../../Assets/contact01.jpg'; // replace with your actual image paths
import image2 from '../../Assets/6.png';
import image3 from '../../Assets/image011.jpeg';
import image4 from '../../Assets/contact02.jpg';

const Contact = () => {
  return (
    <div className='flex flex-col md:flex-row w-full md:w-5/6 mx-auto p-4'>
      <div className='w-full md:w-1/2 grid grid-cols-2 gap-2 md:mt-12'>
        <div className='w-full h-48'>
          <img src={image1} alt="image1" className='w-full h-full object-cover' />
        </div>
        <div className='w-full  md:h-52 h-48'>
          <img src={image2} alt="image2" className='w-full h-full object-cover' />
        </div>
        <div className='w-full h-48 md:-mt-20'>
          <img src={image3} alt="image3" className='w-full h-full object-cover' />
        </div>
        <div className='w-full h-48 md:-mt-20'>
          <img src={image4} alt="image4" className='w-full h-full object-cover' />
        </div>
      </div>
      <div className='w-full md:w-1/2 p-4 md:ml-6'>
        <h2 className='text-2xl md:text-4xl text-[#ee6718] text-center mt-4 md:mt-12'>Work With Us</h2>
        <form className='space-y-4 mt-4 md:mt-8'>
          <div className='flex flex-col md:flex-row items-center'>
            <label className='block text-[#1eb04d] w-full md:w-[30%] mb-2 md:mb-0'>Name*</label>
            <input type='text' className='w-full md:w-[70%] p-2 border border-gray-300 rounded-full' required />
          </div>
          <div className='flex flex-col md:flex-row items-center'>
            <label className='block text-[#1eb04d] w-full md:w-[30%] mb-2 md:mb-0'>Email*</label>
            <input type='email' className='w-full md:w-[70%] p-2 border border-gray-300 rounded-full' required />
          </div>
          <div className='flex flex-col md:flex-row items-center'>
            <label className='block text-[#1eb04d] w-full md:w-[30%] mb-2 md:mb-0'>Phone*</label>
            <input type='tel' className='w-full md:w-[70%] p-2 border border-gray-300 rounded-full' required />
          </div>
          <div>
            <label className='block text-[#1eb04d]'>Link to LinkedIn/CV via Google Drive (grant public access)*</label>
            <input type='url' className='w-full p-2 border border-gray-300 rounded-full' required />
          </div>
          <div>
            <label className='block text-[#1eb04d]'>Why do you like to join us?*</label>
            <textarea className='w-full p-2 border border-gray-300 rounded-full' rows='3' required></textarea>
          </div>
          <div className='flex justify-center'>
            <button type='submit' className='bg-[#ee6718] text-white px-6 py-2 rounded-full'>Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
