import React from 'react';
import l1 from "../../Assets/8.png";
import l2 from "../../Assets/2.png";
import l3 from "../../Assets/9.png";
import l4 from "../../Assets/4.png";
import l5 from "../../Assets/lif2.jpg";

const Life = () => {
  return (
    <div className='mt-12'>
      <div className='flex justify-center mt-4'>
        <h1 className='text-[#1eb04d] ubuntu-bold text-4xl'>
          Life At <span className='text-[#09989e]'>Clo</span><span className='text-[#ee6718]'>wak</span>
        </h1>
      </div>
      <div className='flex flex-wrap justify-center mx-auto w-5/6  gap-3 mt-6'>
        <div className='w-full sm:w-[45%] lg:w-[29%] h-[340px] md:-mt-8'>
          <img src={l1} alt="l1" className='w-full h-full object-cover' />
        </div>
        <div className='w-full sm:w-[50%] lg:w-[45%] h-64'>
          <img src={l2} alt="l2" className='w-full h-full object-cover ' />
        </div>
      </div>
      <div className='flex flex-wrap justify-center mx-auto w-5/6 gap-3 md:-mt-12 mt-3'>
        <div className='w-full sm:w-[45%] lg:w-[25%] md:mt-1 h-72'>
          <img src={l3} alt="l3" className='w-full h-full object-cover' />
        </div>
        <div className='w-full sm:w-[45%] lg:w-[25%] md:-mt-0 h-72'>
          <img src={l4} alt="l4" className='w-full h-full object-cover' />
        </div>
        <div className='w-full sm:w-[45%] lg:w-[25%] md:mt-6 md:h-[220px] h-72'>
          <img src={l5} alt="l5" className='w-full h-full object-cover' />
        </div>
      </div>
    </div>
  );
}

export default Life;
