import React, { useState } from 'react';
import { FaTimes, FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Importing icons from react-icons
import { RiMenu2Line } from "react-icons/ri";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div>
      <div className="flex justify-between items-center p-4 text-[#ee6718]">
        {!isOpen && <RiMenu2Line className="text-3xl sm:text-6xl cursor-pointer" onClick={toggleMenu} />}
      </div>
      <div className={`fixed top-0 right-0 h-full bg-white shadow-md bg-opacity-75 text-black w-full md:w-64 p-4 transition-transform duration-300 ${isOpen ? 'transform translate-x-0' : 'transform translate-x-full'}`}>
        <div className='flex  md:justify-end   mb-4'>
          {isOpen && <FaTimes className="text-2xl sm:text-4xl text-[#ee6718] cursor-pointer rotate-on-hover" onClick={toggleMenu} />}
        </div>
        <ul className="mt-10 space-y-4 text-green-600 ubuntu-bold text-md">
          <li><a href="#" className="hover:text-[#ee6718] transition-colors duration-300">Home</a></li>
          <li className="relative">
            <div className="flex items-center cursor-pointer" onClick={toggleDropdown}>
              <span className="mr-2">Verticals</span>
              {isDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {isDropdownOpen && (
              <ul className="mt-2 space-y-2 pl-4 text-[#333] bg-[#f9f9f9] shadow-lg rounded-lg">
                <li><a href="Launchingsoon" className="block py-2 px-4 hover:text-[#ee6718] hover:bg-gray-100 rounded transition-colors duration-300">Portable Dental Clinic</a></li>
                <li><a href="Launchingsoon" className="block py-2 px-4 hover:text-[#ee6718] hover:bg-gray-100 rounded transition-colors duration-300">Oral Care & Accessories</a></li>
                <li><a href="Launchingsoon" className="block py-2 px-4 hover:text-[#ee6718] hover:bg-gray-100 rounded transition-colors duration-300">Dental Software & Applications</a></li>
                <li><a href="Launchingsoon" className="block py-2 px-4 hover:text-[#ee6718] hover:bg-gray-100 rounded transition-colors duration-300">Dental Stem Cell Research</a></li>
                <li><a href="Launchingsoon" className="block py-2 px-4 hover:text-[#ee6718] hover:bg-gray-100 rounded transition-colors duration-300">Customized Dental Product Development</a></li>
              </ul>
            )}
          </li>
          <li><a href="#" className="hover:text-[#ee6718] transition-colors duration-300">Awards</a></li>
          <li><a href="#" className="hover:text-[#ee6718] transition-colors duration-300">Press</a></li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
