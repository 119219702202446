import React from 'react';
import logo from "../Assets/clwk logo fin-PhotoRoom.png-PhotoRoom.png";
import { MdCall, MdEmail } from "react-icons/md";
import { FaFacebookF, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';

const menuItems = [
  { name: 'Home', link: '/' },
  { name: 'About Us', link: 'About' },
  { name: 'Portable Dental Clinic', link: 'Launchingsoon' },
  { name: 'Oral Care & Accessories', link: 'Launchingsoon' },
  { name: 'Dental Software & Applications', link: 'Launchingsoon' },
  { name: 'Dental Stem Cell Research', link: 'Launchingsoon' },
  { name: 'Customized Dental Product Development', link: 'Launchingsoon' },
  { name: 'Career', link: 'carrer' },
  { name: 'Contact Us', link: 'carrer' },
];

const Footer = () => {
  return (
    <div className='max-h-36'>
      <div className='flex justify-between  p-12'>
        <div>
          <img src={logo} alt="logo" style={{ width: '150px', height: 'auto' }} />
          <div className='-mt-5'>
            <h1 className='text-green-600 text-4xl ubuntu-bold'>Let's collaborate</h1>
            <h2 className='text-xl ubuntu-bold text-[#ee6718]'>Bring Something Big Together!</h2>
          </div>
          <div className='mt-4'>
            <h1 className='text-green-600 text-3xl ubuntu-bold'>Contact With Us</h1>
            <p className='flex text-[#ee6718] ubuntu-bold text-md'>
              <MdCall className='text-black size-7' />+91 8072375909
            </p>
            <p className='flex text-[#ee6718] ubuntu-bold text-md'>
              <MdEmail className='text-black size-7' />clowakinnovations@gmail.com
            </p>
            <p className='flex text-[#ee6718] ubuntu-bold text-md'>
              <MdEmail className='text-black size-7' />support@clowakinnovations.com
            </p>
          </div>
        </div>
        <div className='p-6'>
          <h1 className='text-green-600 ubuntu-bold text-right text-3xl'>Useful links</h1>
          <ul className="space-y-2 ubuntu-bold text-right">
            {menuItems.map((item, index) => (
              <li key={index} className="text-[#ee6718]">
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className='flex justify-between p-6'>
        <span className='flex p-4 space-x-3'>
        <a href='https://www.linkedin.com/company/clowak-innovations-llp/?viewAsMember=true' target='_blank' rel='noopener noreferrer'>
              <FaLinkedin size={24}  className='text-[#1055B2]' />
            </a>
            <a href='https://www.instagram.com/clowak_innovations/' target='_blank' rel='noopener noreferrer'>
              <FaInstagram size={24} className='text-[#F07F3C]' />
            </a>
            <a href='https://www.facebook.com/CLOWAKINNOVATIONS/' target='_blank' rel='noopener noreferrer'>
              <FaFacebookF size={24} className='text-[#5271FF]' />
            </a>
            <a href='https://www.twitter.com' target='_blank' rel='noopener noreferrer'>
              <FaTwitter size={24} className='text-[#0CC0DF]' />
            </a>
        </span>
        <span className='text-green-600 ubuntu-bold p-4'>© Clowakinnovations</span>
        <span className='text-[#09989e] ubuntu-bold p-4'>Designed by Knackbay</span>
      </div>
    </div>
  );
};

export default Footer;
