import React from 'react';
import launch from '../Assets/launching.png';
import { FaFacebookF, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
// import { FaLinkedin } from "react-icons/fa6";

const LaunchPage = () => {
  return (
    <div className='flex flex-col md:flex-row w-full h-screen max-w-screen-2xl mx-auto'>
      <div className='md:w-[60%] flex flex-col justify-center p-7'>
        <div className='text-8xl p-4 text-[#1eb04d] ubuntu-regular'>
          <h1>Launching</h1>
          <h2>soon</h2>
        </div>
        <div className='text-[#ee6718] ubuntu-bold text-lg md:text-xl'>
          <p>
            We are excited to announce that Clowak Innovations LLP will be launching soon! Stay tuned for innovative dental products and solutions designed to elevate oral health and transform patient care.
          </p>
        </div>
        <div className='mt-6'>
          <button className='bg-black text-white px-8 py-3 rounded text-md hover:bg-[#333]'>
            Contact Us
          </button>
        </div>
        <div className='flex items-center space-x-3 mt-6'>
          <h1 className='text-[#09989e] text-3xl ubuntu-bold'>Get Social</h1>
          <hr className='w-24 bg-[#ee6718] h-0.5 rounded-2xl' />
          <span className='flex space-x-3'>
            <a href='https://www.linkedin.com/company/clowak-innovations-llp/?viewAsMember=true' target='_blank' rel='noopener noreferrer'>
              <FaLinkedin size={24}  className='text-[#1055B2]' />
            </a>
            <a href='https://www.instagram.com/clowak_innovations/' target='_blank' rel='noopener noreferrer'>
              <FaInstagram size={24} className='text-[#F07F3C]' />
            </a>
            <a href='https://www.facebook.com/CLOWAKINNOVATIONS/' target='_blank' rel='noopener noreferrer'>
              <FaFacebookF size={24} className='text-[#5271FF]' />
            </a>
            <a href='https://www.twitter.com' target='_blank' rel='noopener noreferrer'>
              <FaTwitter size={24} className='text-[#0CC0DF]' />
            </a>
          </span>
        </div>
      </div>
      <div className='w-full md:w-[50%] flex items-center justify-center md:justify-end'>
        <img src={launch} alt="Launching soon" className='w-full h-full object-cover' />
      </div>
    </div>
  );
}

export default LaunchPage;
