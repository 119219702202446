import React from 'react'
import Navbar from './Navbar'
import Home from './Home'
import Vertical from './verticals/Vertical'
import Aboutus from './Aboutus'
import Awards from './Awards/Awards'
 
import Footer from './Footer'
import Press from './prees/Press'

const Main = () => {
  return (
    <div>
        
        <Home/>
            <Vertical/>
            <Aboutus/> 
            <Awards/>  
            <Press/>
            <Footer/>
    </div>
  )
}

export default Main