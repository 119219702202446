import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const awards = [
  { image: require('../../Assets/26.png'), logo: require('../../Assets/logo1.png'), link: 'https://thelogicalindian.com/smb-stories/portable-dental-clinic-35286' },
  { image: require('../../Assets/27.png'), logo: require('../../Assets/logo2.jpg'), link: 'https://startupstorymedia.com/stories-2022-06-clowak-innovations-startup-story/' },
  { image: require('../../Assets/25.png'), logo: require('../../Assets/logo3.png'), link: 'https://www.youtube.com/watch?v=GbHLMCh09T4' },
  { image: require('../../Assets/24.png'), logo: null, link: 'https://www.youtube.com/watch?v=FJjHpzTmwVM' },
  { image: require('../../Assets/23.png'), logo: null, link: 'https://www.linkedin.com/feed/update/urn:li:activity:6934710328949641216/' },
  { image: require('../../Assets/cm.JPG'), logo: null, link: 'https://example.com/award6' },
 
];

const Press = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = 4;

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? awards.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === awards.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === awards.length - 1 ? 0 : prevIndex + 1
      );
    }, 4000); // Change images every 4 seconds

    return () => clearInterval(interval);
  }, []);

  const getVisibleAwards = () => {
    let visibleAwards = [];
    for (let i = 0; i < itemsPerPage; i++) {
      visibleAwards.push(awards[(currentIndex + i) % awards.length]);
    }
    return visibleAwards;
  };

  return (
    <div className="flex flex-col items-center mt-6 p-4">
      <div className="flex justify-center text-green-600 text-3xl md:text-6xl ubuntu-bold">
        <h1>Press</h1>
      </div>
      <div className="relative w-full max-w-5xl mt-6">
        <button
          onClick={handlePrevClick}
          className="absolute left-0 top-1/2 z-50 transform -translate-y-1/2 p-2"
        >
          <FaChevronLeft className="text-3xl md:text-6xl text-[#1eb04d]" />
        </button>
        <div className="flex justify-center space-x-4 md:space-x-4 overflow-hidden">
          {getVisibleAwards().map((award, index) => (
            <div key={index} className="relative w-40 h-56 md:w-56 md:h-72">
              <a href={award.link} target="_blank" rel="noopener noreferrer">
                <img src={award.image} alt={`Award ${currentIndex + index + 1}`} className="w-full h-full object-cover" />
                {award.logo && (
                  <img src={award.logo} alt={`Logo ${currentIndex + index + 1}`} className="absolute top-4 right-4 md:top-4 md:right-5 w-10 h-10 md:w-16 md:h-16" />
                )}
              </a>
            </div>
          ))}
        </div>
        <button
          onClick={handleNextClick}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2"
        >
          <FaChevronRight className="text-3xl md:text-6xl text-[#ee6718]" />
        </button>
      </div>
    </div>
  );
};

export default Press;
