import React from 'react';
import logo from "../Assets/clwk logo fin-PhotoRoom.png-PhotoRoom.png";
import img1 from "../Assets/17.png";
import img2 from "../Assets/18.png";
import img3 from "../Assets/19.png";

const Home = () => {
  return (
    <div className="relative p-4">
      {/* Logo Section */}
      <div className="absolute top-4 left-4">
        <img 
          src={logo} 
          alt="logo" 
          className="w-12 xs:w-20 sm:w-24 md:w-28 lg:w-32" 
        />
      </div>
      {/* Main Content */}
      <div className="flex flex-col items-center justify-center my-10 md:my-20 h-full">
        {/* Images Section */}
        <div className="flex flex-wrap justify-center gap-4 mb-4">
          <img 
            src={img1} 
            alt="img1" 
            className="w-16 xs:w-20 sm:w-24 md:w-28 lg:w-32" 
          />
          <img 
            src={img2} 
            alt="img2" 
            className="w-16 xs:w-20 sm:w-24 md:w-28 lg:w-32" 
          />
          <img 
            src={img3} 
            alt="img3" 
            className="w-16 xs:w-20 sm:w-24 md:w-28 lg:w-32" 
          />
        </div>
        {/* Text Section */}
        <div className="text-center ubuntu-bold space-y-2 px-4">
          <h1 className="text-xl xs:text-2xl sm:text-3xl md:text-5xl lg:text-6xl font-semibold">Innovating</h1>
          <h2 className="text-xl xs:text-2xl sm:text-3xl md:text-5xl lg:text-6xl ">
            <span className=' text-[#1eb04d]'>Smiles </span>,
          </h2>
          <h3 className="text-xl xs:text-2xl sm:text-3xl md:text-5xl lg:text-6xl">Transforming</h3>
          <h4 className="text-xl xs:text-2xl sm:text-3xl md:text-5xl lg:text-6xl text-[#ee6718] ">
            <span>Lives</span>
          </h4>
        </div>
      </div>
    </div>
  );
}

export default Home;
