import React from 'react'
import Main from './Main'
import Navbar from './Navbar'

const Mainui = () => {
  return (
    <div>
        <Main/>
      <aside className=' flex md:justify-end  justify-end  md:ml-0 bottom-full  md:bottom-3/4 sticky '>
            <Navbar/>
        </aside>
    </div>
  )
}

export default Mainui