import React from 'react';
import about from "../Assets/about.png";
import { Link } from 'react-router-dom';

const Aboutus = () => {
  return (
    <div>
      <div className='flex justify-center mt-4'>
        <h1 className='text-[#1eb04d] text-6xl ubuntu-bold'>
          ABOUT <span className='text-[#ee6718] text-6xl ubuntu-bold'>US</span>
        </h1>
      </div>
      <div className='flex flex-col md:flex-row justify-center items-center md:items-start'>
        <div className='w-full md:w-1/2 p-6'>
          <p className='text-xl md:text-2xl ubuntu-bold text-justify py-4'>
            <span className='text-[#09989e]'>Clo<span className='text-[#ee6718]'>wak</span></span> was established by a team of experts who recognized the need for more innovative and effective dental solutions.
          </p>
          <p className='text-xl md:text-2xl ubuntu-bold text-justify py-4'>
            Driven by a commitment to improving oral health, we have built a company that combines rigorous scientific research with practical applications to create products that make a real difference in people's lives.
           <Link  to ="About">
            <button className='hover:shadow-md text-sm text-green-600 p-1 ml-0 md:ml-4 px-4 py-2 mt-4 md:mt-0 bg-transparent border border-black rounded-md'>
              About Us
            </button>
            </Link>
          </p>
        </div>
        <div className='w-full md:w-1/3 p-6'>
          <img src={about} alt="about" className='w-full h-auto'/>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
