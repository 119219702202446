import React from 'react';
import portabledental from '../../Assets/portabledentailclinc.jpg';
import oralcare from '../../Assets/orealcare.png';
import dentalsoftware from '../../Assets/dentalsoftware application.png';
import dentalstem from '../../Assets/steamcell.png';
import logo from '../../Assets/clwk logo fin-PhotoRoom.png-PhotoRoom.png';
import { Link } from 'react-router-dom';

const Vertical = () => {
  return (
    <>
      <div className='md:mt-12'>
        <div className='flex justify-center relative'>
          <h1 className='text-[#ee6718] xs:text-3xl sm:text-3xl md:text-6xl ubuntu-bold py-4 absolute z-10 px-2 shadow-text'>
            VERTICALS
          </h1>
        </div>
        <div className='mt-12 flex justify-center  relative space-x-2'>
          <div className='group relative h-[355px] -mt-2  w-36 md:w-56 overflow-hidden'>
            <img src={portabledental} alt='portable' className='h-full w-full object-cover' />
            <Link to="Launchingsoon">
              <button className='absolute bottom-3 left-1/2 text-sm hover:shadow-md transform -translate-x-1/2 px-4 py-2 bg-white text-black rounded-md opacity-0 group-hover:opacity-100 transition-opacity'>
                Portable Dental Clinic
              </button>
            </Link>
          </div>
          <div className='group relative h-[535px] -mt-20  w-36 md:w-56  overflow-hidden'>
            <img src={dentalstem} alt='oralcare' className='h-full w-full object-cover' />
            <Link to="Launchingsoon">
              <button className='absolute bottom-28 mb-2 left-1/2 text-sm hover:shadow-md transform -translate-x-1/2 px-4 py-2 bg-white  text-black rounded-md opacity-0 group-hover:opacity-100 transition-opacity'>
                Oral Care & Accessories
              </button>
            </Link>
          </div>
          <div className='group relative h-[480px] w-36 md:w-56  -mt-16 overflow-hidden'>
            <img src={dentalsoftware} alt='dental software' className='h-full w-full object-cover' />
            <Link to="Launchingsoon">
              <button className='absolute bottom-16 mb-3 left-1/2 text-sm hover:shadow-md transform -translate-x-1/2 py-2 bg-white  text-black rounded-md opacity-0 group-hover:opacity-100 transition-opacity'>
                Dental Software & Applications
              </button>
            </Link>
          </div>
          <div className='group relative h-[480px]  w-36 md:w-56  -mt-14 overflow-hidden'>
            <img src={oralcare} alt='stem' className='h-full w-full object-cover' />
            <Link to="Launchingsoon">
              <button className='absolute bottom-16 left-1/2 text-sm hover:shadow-md transform -translate-x-1/2 py-2 mb-5 bg-white  text-black rounded-md opacity-0 group-hover:opacity-100 transition-opacity'>
                Dental Stem Cell Research
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className='flex justify-center -mt-12'>
        <div className='w-[79%] h-auto md:h-36 rounded-md bg-green-100 p-2'>
          <div className='flex flex-col md:flex-row justify-center items-center md:items-start space-y-4 md:space-y-0 md:space-x-4'>
            <img src={logo} alt='logo' className='h-16 md:h-28' />
            <div className='text-green-800 text-xl md:text-4xl ubuntu-medium text-center md:text-left'>
              <h1>Bring </h1>
              <h2>Something Big</h2>
              <h3>Together!</h3>
            </div>
            <div className='py-8'>
              <Link to="Launchingsoon">
                <button className='text-xs h-12 md:h-14 w-36 hover:shadow-md ml-0 md:ml-16 ubuntu-bold bg-transparent border border-black text-black rounded-md'>
                  Customized Dental Product Development
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Vertical;
 