import React from 'react';
import team1 from '../../Assets/team01.png';
import team2 from '../../Assets/team02.png';
import team3 from '../../Assets/team03.png';
import team4 from '../../Assets/team04.png';
import team5 from '../../Assets/team05.png';
import team6 from '../../Assets/team06.png';
import team7 from '../../Assets/team07.png';

const teamMembers = [
  { img: team1, name: 'Mr Surya  M  Rajendran ', position: 'Co - Founder & CEO' },
  { img: team2, name: 'Dr Rakshini Pravitha', position: 'Co - Founder & CEO' },
  { img: team3, name: 'Mr Shyam Sundar', position: 'Project Lead - Hardware' },
  { img: team4, name: 'Mr Ajith', position: 'Project Lead - Software' },
  { img: team5, name: 'Ms Bhoomika', position: 'Lead - Operations' },
  { img: team6, name: 'Mr Naveen Kumar', position: 'Lead - Sales & Marketing' },
  { img: team7, name: 'Mr Krishna Pranav', position: 'Lead - Product Development' },
];

const Team = () => {
  return (
    <div className='flex flex-col items-center mt-10'>
      <div className='flex justify-center mt-4'>
        <h1 className='text-[#1eb04d] ubuntu-bold text-4xl'>
          Our <span className='text-[#ee6718]'>Leadership</span> <span className='text-[#1eb04d]'>Team</span>
        </h1>
      </div>
      <div className='flex flex-wrap justify-center mt-8'>
        {teamMembers.map((member, index) => (
          <div key={index} className='flex flex-col items-center  mb-6  ubuntu-bold'>
            <img src={member.img} alt={`team${index + 1}`} className='h-36 mb-2 ml-6' />
            <p className=' text-[#ee6718] text-start'>{member.name}</p>
            <p className='text-center text-xs text-[#1eb04d]'>{member.position}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
