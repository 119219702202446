import React from 'react';
import cm from "../../Assets/cm02.png"
import Team from './Team';
import Life from './Life';
import Contact from './Contact';

const About = () => {
  return (
    <>
     <div className='flex justify-center mt-4'>
        <h1 className='text-[#1eb04d] text-6xl ubuntu-bold'>
          ABOUT <span className='text-[#ee6718] text-6xl ubuntu-bold'>US</span>
        </h1>
      </div>
      <div className='mx-4 md:ml-12 mt-12'>
        <div className='flex flex-col md:flex-row justify-between w-full '>
          <div className='md:w-[70%] p-4 ubuntu-bold text-left'>
            <h1>Welcome to <span className='text-[#09989e]'>Clo</span><span className='text-[#ee6718]'>wak</span> Innovations LLP</h1>
            <p>We are passionate about advancing oral care through innovation and excellence. Founded with a vision to revolutionize dental health, we are dedicated to developing cutting-edge products and solutions that meet the diverse needs of dental professionals and patients worldwide.</p>
            <p className='py-3'>Our name, ' <span className='text-[#09989e]'>Clo</span><span className='text-[#ee6718]'>wak</span>,' originates from a blend of two powerful natural remedies: <span className='text-[#1eb04d]'>Clove</span>  and <span className='text-[#ee6718]'>Miswak</span>. These time-honored ingredients have been used for centuries in traditional dental care, known for their natural healing properties and effectiveness in promoting oral health.</p>
          </div>
          <div className='w-full md:w-[60%] p-2'>
            <img src={cm} alt="cm" className="w-full h-auto" />
          </div>
        </div>
        
        <div className='flex flex-col md:flex-row p-4 ubuntu-bold justify-between md:ml-7'>
          <div className='mb-6 md:mb-0'>
            <h1 className='text-[#1eb04d] text-center w-full md:w-5/6'>Our Mission</h1>
            <p className='w-full md:w-5/6'>To empower dental professionals and enhance patient care through the development of innovative, high-quality dental solutions. We are dedicated to advancing <span className='text-[#ee6718]'>oral health</span> by integrating cutting-edge technology, rigorous research, and a deep understanding of patient needs into every product and service we offer.</p>
          </div>
          <div>
            <h1 className='text-[#1eb04d] text-center w-full md:w-5/6'>Our Vision</h1>
            <p className='w-full md:w-5/6'>To become a global leader in dental innovation by seamlessly integrating traditional wisdom with cutting-edge technology. We aim to set new standards in  <span className='text-[#ee6718]'>oral care</span>, ensuring that advanced, effective, and accessible solutions are available to everyone, improving health outcomes and quality of life worldwide.</p>
          </div>
        </div>
      </div>
      <Team/>
      <Life/>
      <Contact/>
    </>
  );
}

export default About;
