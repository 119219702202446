import logo from './logo.svg';
import './App.css';
import Home from './components/Home';
import Main from './components/Main';
import Navbar from './components/Navbar';
import About from './components/About/About';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Mainui from './components/Mainui';
import LaunchPage from './components/LaunchPage';
import Contact from './components/About/Contact';
function App() {
  return (
    <div className="App">
       
         <BrowserRouter>
        <Routes>
        <Route index element={<Mainui />} />

       
        <Route path="About" element={<About />} />
        <Route path="Launchingsoon" element={<LaunchPage />} />
        <Route path="carrer" element={<Contact />} />


        





 
</Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
