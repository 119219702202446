import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link } from 'react-router-dom';

const awards = [
  require('../../Assets/22.png'),
  require('../../Assets/copy2.png'),
  require('../../Assets/13.png'),
  require('../../Assets/copy3.png'),
  require('../../Assets/ENTREOVERT logo.jpg'),
  require('../../Assets/15.png'),
  require('../../Assets/14.png'),
  require('../../Assets/21.png'),
  require('../../Assets/copy2.png'),       
  require('../../Assets/copy1.png'),
  require('../../Assets/copy3.png'),
  require('../../Assets/12.png'),
  
];

const Awards = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = 4;

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? awards.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === awards.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === awards.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change images every 3 seconds

    return () => clearInterval(interval);
  }, []);

  const getVisibleAwards = () => {
    let visibleAwards = [];
    for (let i = 0; i < itemsPerPage; i++) {
      visibleAwards.push(awards[(currentIndex + i) % awards.length]);
    }
    return visibleAwards;
  };

  return (
    <div className='flex flex-col items-center'>
      <div className='flex justify-center mt-4 text-center flex-wrap'>
        <p className='text-green-600 text-3xl md:text-5xl ubuntu-bold'>Awards</p>
        <span className='text-xl md:text-3xl px-3'>|</span>
        <p className='text-3xl md:text-5xl ubuntu-bold text-[#ee6718]'>Recognition</p>
        <span className='text-xl md:text-3xl px-3'>|</span>
        <p className='text-3xl md:text-5xl ubuntu-bold text-[#09989e]'>Supported</p>
      </div>
      <div className='relative w-full max-w-5xl mt-6'>
        <button
          onClick={handlePrevClick}
          className='absolute left-0 top-1/2 transform -translate-y-1/2 p-2'
        >
          <FaChevronLeft className='text-3xl md:text-6xl text-[#1eb04d]' />
        </button>
        <div className='flex justify-center space-x-4 md:space-x-9 overflow-hidden'>
          {getVisibleAwards().map((award, index) => (
            <img key={index} src={award} alt={`Award ${currentIndex + index + 1}`} className='max-h-24 md:max-h-40 mx-1 md:mx-2' />
          ))}
        </div>
        <button
          onClick={handleNextClick}
          className='absolute right-0 top-1/2 transform -translate-y-1/2 p-2'
        >
          <FaChevronRight className='text-3xl md:text-6xl text-[#ee6718]' />
        </button>
      </div>
      <div className='flex flex-col md:flex-row space-y-4 md:space-y-0 space-x-0 md:space-x-5 mt-6 text-center md:text-left'>
        <div>
          <h1 className='text-green-600 text-2xl md:text-4xl ubuntu-bold'>Let's collaborate</h1>
          <h2 className='text-lg md:text-xl ubuntu-bold text-[#ee6718]'>Bring Something Big Together!</h2>
        </div>
        <div>
          <Link to ="carrer">
          <button className='text-md px-8 py-3 mt-3 hover:shadow-md ubuntu-bold bg-transparent border border-black text-[#09989e] rounded-md'>
            Contact Us
          </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Awards;
